/**
 *  @description
 *  Actions related to tokens.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import * as actionTypes from '../types/token-action-types';
import {
    API_URI
} from '../../config/app-constants';
import api from '../../config/api';

export const startRequest = () => ({
    type: actionTypes.START_REQUEST_GET_TOKEN,
});

export const receiveData = (data) => ({
    type: actionTypes.RECEIVE_TOKEN,
    payload: data,
});

export const receiveError = (error) => ({
    type: actionTypes.ERROR_RECEIVING_TOKEN,
    payload: error,
});

export function getTokenForService(mobile_number, service_id, patient_id, has_senior_citizen, token_for) {
    return (dispatch) => {
        dispatch(startRequest());
        let url = API_URI + '/tokens?service_id=' + service_id + '&mobile_no='+ mobile_number;
        if((service_id != 1 || service_id != 3) && token_for == false){
           url += '&skip_registration=1';
        }
        if (patient_id) {
            if(token_for === "has_patients" && has_senior_citizen === 'true'){
                url += '&patient_id=' + patient_id;
            }else if(token_for === "has_patients" && has_senior_citizen === 'false'){
                url += '&kiosk_patient_id=' + patient_id;
            }else{                
                url += '&booking_id=' + patient_id;
            }
            
        }
        if(has_senior_citizen === 'true'){
            url += '&has_senior_citizen=true';
        }
        //return api.get(API_URI + '/tokens?service_id=' + service_id + '&mobile_number='+ mobile_number)
        return api.get(url)
            .then(
                response => {
                    dispatch(receiveData(response));
                    return response;
                },
                error => {
                    console.log(error);
                    dispatch(receiveError(error));
                }
            );
    }
}

export function getTokenForAppointment(mobile_number, booking_code, service_id) {
    return (dispatch) => {
        dispatch(startRequest());
        let url = API_URI + '/appointment?service_id=' + service_id;
        if (mobile_number && mobile_number.trim() !== '' && mobile_number.trim().length === 10) {
            url += '&mobile_number=' + mobile_number;
        }
        if (booking_code && booking_code.trim() !== '') {
            url += '&booking_code=' + booking_code;
        }
        return api.get(url)
            .then(
                response => {
                    dispatch(receiveData(response));
                    return response;
                },
                error => {
                    console.log(error);
                    dispatch(receiveError(error));
                    return error;
                }
            )
    }
}