/**
 *  @description
 *  Pairing Container
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import {
    getPairingCode
} from '../actions/pairing-actions';
import PairingComponent from '../components/pairing/pairing-component';
import { 
    APP_SUB_FOLDER,
    POLLING_DELAY
 } from '../../config/app-constants';

class PairingContainer extends Component {

    componentDidMount() {
        // Using clientJS for getting device_id using Browser fingerprinting.
        this.getPairingCode();
        this.timer = setInterval(() => {
            this.getPairingCode();
        },
        POLLING_DELAY);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getPairingCode() {
        if(window.ClientJS) {
            // Using clientJS for getting device_id using Browser fingerprinting.
            let device_id = (this.props.pair && this.props.pair.data) ? this.props.pair.data.device_id : null;
            if(!device_id) {
                device_id = "kiosk_" + new window.ClientJS().getFingerprint();
            }
            this.props.getPairingCode({device_id: device_id});
        }
    }

    render() {
        if(this.props.pair && this.props.pair.data && this.props.pair.data.data.status) {
            this.props.history.push(APP_SUB_FOLDER+"/services");
        }
        return(
            <PairingComponent pair={this.props.pair} />
        ) 
    }
};

const mapStateToProps = state => ({
    pair: state.pair,
    //websocket: state.websocket,
});

const mapStateToDispatch = dispatch => ({
    getPairingCode: (device_details) => dispatch(getPairingCode(device_details)),
});

PairingContainer = connect(mapStateToProps, mapStateToDispatch)(PairingContainer);

export default PairingContainer;