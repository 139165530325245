/**
 *  @description
 *  Services Container
 *  @author
 *  Mahesh Hegde<mahesh@innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import {
    getServices
} from '../actions/service-actions';
import {
    getTokenForService,
    getTokenForAppointment
} from '../actions/token-actions';

import { resetPairing } from '../actions/pairing-actions';
import {
    Modal,
    ModalBody,
} from 'reactstrap';
import ServicesComponent from '../components/services/services-component';
import TokenComponent from '../components/services/token-component';
import AppointmentFormComponent from '../components/services/appointment-form-component';
import MobileNumberFormComponent from '../components/services/mobile-number-form-component';
import MobileNumberNotExist from '../components/services/mobile-number-not-exists-component';
import PatientsListModal from '../components/services/patients-list';
import {
    POLLING_DELAY,
    TOKEN_DIALOG_TIMEOUT,
} from '../../config/app-constants';

class ServicesContainer extends Component {

    constructor({ match }) {
        super();
        this.match = match;
        this.state = {
            has_booking: false,
            selected_service: null,
            mobile_number_modal: false,
            token_modal: false,
            appointment_modal: false,
            no_UserExists_modal:false,
        };
        this.toggleTokenModal = this.toggleTokenModal.bind(this);
        this.closeTokenModal = this.closeTokenModal.bind(this);
        this.toggleAppointmentModal = this.toggleAppointmentModal.bind(this);
        this.toggleMobileNumberModal = this.toggleMobileNumberModal.bind(this);
        this.closeMobileNumberModal = this.closeMobileNumberModal.bind(this);
        this.getTokenForBooking = this.getTokenForBooking.bind(this);
        this.getToken = this.getToken.bind(this);
        this.getWalkinToken = this.getWalkinToken.bind(this);
        this.onServiceClicked = this.onServiceClicked.bind(this);
        this.toggleNoUserExistsModal = this.toggleNoUserExistsModal.bind(this);
        this.togglePatientsListModal = this.togglePatientsListModal.bind(this);
    };

    componentDidMount() {
        this.getServices();
        this.timer = setInterval(() => {
            this.getServices();
        }, POLLING_DELAY);
        
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    toggleTokenModal() {
        this.setState({
            token_modal: !this.state.token_modal,
        });
    };

    closeTokenModal() {
        this.setState({
            token_modal: false
        })
    }

    toggleAppointmentModal() {
        this.setState({
            appointment_modal: !this.state.appointment_modal,
        });
    };

    toggleMobileNumberModal() {
        this.setState({
            mobile_number_modal: !this.state.mobile_number_modal,
        });
    };

    closeAppointmentModal() {
        this.setState({
            appointment_modal: false
        })
    }

    closeMobileNumberModal() {
        this.setState({
            mobile_number_modal: false
        })
    }

    toggleNoUserExistsModal() {
        this.setState({
            no_UserExists_modal: !this.state.no_UserExists_modal,
        });
    };
    closeNoUserExistsModal() {
        this.setState({
            no_UserExists_modal: false
        })
    }

    togglePatientsListModal(){
        this.setState({
            PatientsList_Modal: !this.state.PatientsList_Modal,
        });
    }

    closePatientsListModal(){
        this.setState({
            PatientsList_Modal: false,
        });
    }

    getToken(e, data) {
        e.preventDefault();
        this.setState({
            mobile_number: data.mobile_number,
        })
        
        this.props.getToken(data.mobile_number, this.state.selected_service.id)
            .then((data) => {
                // For Senior Citizen
                
                if (data.data && this.state.selected_service.id == 1) {
                    this.closeMobileNumberModal();
                    if (data.data.results && data.data.results.status == true) {                        
                        this.setState({
                            PatientsDataList: data.data.results
                        })

                        if(data.data.results.patients.length > 1){
                            this.togglePatientsListModal();
                        }else{
                            
                            let patient_id = data.data.results.patients[0].id;
                            let mobileNumber = this.state.mobile_number;
                            let service_id = this.state.selected_service.id;
                            let token_for;

                            if (data.data.results.has_appointment && data.data.results.has_appointment == true) { 
                                token_for = 'has_appointment';
                            }

                            if (data.data.results.has_patients && data.data.results.has_patients == true) { 
                                token_for = 'has_patients';
                            }

                            // console.log(mobileNumber, service_id, patient_id, 'true', token_for);
                            this.props.getTokenForSeniorCitize(mobileNumber, service_id, patient_id, 'true', token_for)
                            .then((data) => {
                                if (data.data) {
                                    this.toggleTokenModal();
                                    setTimeout(() => {
                                        this.closeTokenModal();
                                    }, TOKEN_DIALOG_TIMEOUT);
                                }
                            });

                        }
                    }else{
                             this.toggleNoUserExistsModal();
                    }
                    
                }

                // Other Services
                if (data.data && this.state.selected_service.id != 1) {
                    this.closeMobileNumberModal();
                    if (data && (data.statusText == 'OK' || data.status == 200)) {            
                        this.setState({
                            PatientsDataList: data.data
                        })

                        if(data.data.patients && data.data.patients.length > 1){
                            this.togglePatientsListModal();
                        }
                        else if(data.data.service && data.data.service.status == false){
                            this.toggleNoUserExistsModal();
                        }else{
                            this.toggleTokenModal();
                            setTimeout(() => {
                                this.closeTokenModal();
                            }, TOKEN_DIALOG_TIMEOUT);

                        }
                    }else{
                            this.toggleTokenModal();
                                setTimeout(() => {
                                    this.closeTokenModal();
                                }, TOKEN_DIALOG_TIMEOUT);
                        
                    }
                    
                }
            });
    }

   async getTokenForBooking(e, data) {
        e.preventDefault();
        this.setState({
            mobile_number: data.mobile_number
        })

        await this.props.getTokenForAppointment(data.mobile_number, data.booking_code, this.state.selected_service.id)
            .then((data) => {
                
                if (data.data) {
                    if (!data.data.bookings) {
                        this.setState({
                            has_booking: false
                        })
                        // For New User
                        if(data.data.service){
                                this.toggleNoUserExistsModal();
                        }else{
                            this.toggleTokenModal();
                            setTimeout(() => {
                                this.closeTokenModal();
                            }, TOKEN_DIALOG_TIMEOUT);
                         }
                        this.toggleAppointmentModal();
                    }else{
                        this.setState({
                            has_booking: true
                        })
                    }
                }
            });
    }

    getWalkinToken(e, data, service_id, has_senior_citizen, token){
        e.preventDefault();
        if(has_senior_citizen === 'true'){
                this.props.getTokenForSeniorCitize(data.mobile_number, service_id, null, 'true')
                .then((data) => {
                    if (data.data) {
                        this.closePatientsListModal();
                        if(!token){
                            this.toggleTokenModal();
                        }
                        
                        setTimeout(() => {
                            this.closeTokenModal();
                        }, TOKEN_DIALOG_TIMEOUT);
                    }
                });
        }else{
                this.props.getToken(data.mobile_number, service_id)
                .then((data) => {
                    if (data.data) {
                        this.closeAppointmentModal();
                        this.closeMobileNumberModal();

                        if(!token){
                            this.toggleTokenModal();
                        }
                        setTimeout(() => {
                            this.closeTokenModal();
                        }, TOKEN_DIALOG_TIMEOUT);
                    }
                });
            }   
    }

    /** Show mobile number form or appointment form on sevice clicked. */
    onServiceClicked(service) {
        // this.setState({
        //     appointment_modal: !this.state.appointment_modal,
        //     selected_service: service,
        // });

        // if(this.state.appointment_modal == true){
        //     console.log("modal open");
        // }else{
        //     console.log("modal clsoed")
        // }

        if (service.next_step === 1) {
            this.setState({
                appointment_modal: !this.state.appointment_modal,
                selected_service: service,
            });
        } else {
            this.setState({
                mobile_number_modal: true,
                selected_service: service
            });
        }
    };

    // Check if BatterManager APIs are supported before dispatching getServices
    getServices() {
        if (window.ClientJS) {
            const client = new window.ClientJS();
            let device_details = {
                device_model_number: client.getDevice() ? (client.getDevice() + " - " + client.getDeviceVendor()) : client.getOS() ? (client.getOS() + " - " + client.getOSVersion()) : "Device",
                battery_percentage: 0,
            }
            if (navigator.getBattery) {
                navigator.getBattery().then((battery) => {
                    device_details.battery_percentage = battery.level * 100 ? (battery.level * 100) : 0;
                    this.props.getServices(device_details);
                });
            } else {
                this.props.getServices(device_details);
            }
        }
    }

    render() {
        var services = this.props.services;
        if (services.error && services.error.response && services.error.response.status === 401) {
            this.props.resetPairing();
            this.props.history.push("/");
        }

        return (
            <div>
                <ServicesComponent
                    services={this.props.services}
                    branch={this.props.services}
                    onServiceClicked={this.onServiceClicked} />

                    {/* Modal dialogs for Token and Appointment */}
                    <Modal isOpen={this.state.mobile_number_modal} toggle={this.toggleMobileNumberModal} size="md" centered={true} className="token-modal">
                        <ModalBody>
                            <MobileNumberFormComponent
                                toggle={this.toggleMobileNumberModal}
                                service={this.state.selected_service}
                                getToken={this.getToken} />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.token_modal} toggle={this.toggleTokenModal} size="md" centered={true} className="token-modal">
                        <ModalBody className="p-0">
                            <TokenComponent
                                toggle={this.toggleTokenModal}
                                token={this.props.token}
                                service={this.state.selected_service}
                                mobileNumber = {this.state.mobile_number}
                                PatientsDataList = {this.state.PatientsDataList}
                                getWalkinToken = {this.getWalkinToken}
                                
                            />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.appointment_modal} toggle={this.toggleAppointmentModal} size="lg" centered={true} className="token-modal">
                        <ModalBody className="p-0">
                            <AppointmentFormComponent
                                toggle={this.toggleAppointmentModal}
                                token={this.props.token}
                                service={this.state.selected_service}
                                services={this.props.services}
                                getToken={this.getToken}
                                getWalkinToken = {this.getWalkinToken}
                                getTokenForBooking={this.getTokenForBooking} />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.no_UserExists_modal} toggle={this.toggleNoUserExistsModal} size="md" centered={true} className="token-modal">
                        <ModalBody className="p-0">
                            <MobileNumberNotExist
                                toggle={this.toggleNoUserExistsModal}
                                mobileNumber = {this.state.mobile_number}
                                service={this.state.selected_service}
                                token={this.props.token}                            
                                getTokenForBooking={this.getTokenForBooking}
                                toggleTokenModal = {this.toggleTokenModal}
                                closeTokenModal = {this.closeTokenModal}
                                toggleNoUserExistsModal = {this.toggleNoUserExistsModal}
                                /> 
                        </ModalBody>
                    </Modal>


                    <Modal isOpen={this.state.PatientsList_Modal} toggle={this.togglePatientsListModal} size="lg" centered={true} className="token-modal">
                        <ModalBody className="p-0">
                            <PatientsListModal
                                toggle={this.togglePatientsListModal}
                                mobileNumber = {this.state.mobile_number}
                                booking_code = {this.state.booking_code}
                                PatientsDataList = {this.state.PatientsDataList}
                                service={this.state.selected_service}
                                
                                toggleTokenModal = {this.toggleTokenModal}
                                togglePatientsListModal = {this.togglePatientsListModal}
                                closeTokenModal = {this.closeTokenModal}
                                getWalkinToken = {this.getWalkinToken}
                                

                                /> 
                        </ModalBody>
                    </Modal>
            </div>
        )
    };
};

const mapStateToProps = state => ({
    pair: state.pair,
    services: state.services,
    //websocket: state.websocket,
    token: state.token
});

const mapStateToDispatch = dispatch => ({
    resetPairing: () => dispatch(resetPairing()),
    getServices: (device_details) => dispatch(getServices(device_details)),
    getToken: (mobile_number, service_id) => dispatch(getTokenForService(mobile_number, service_id)),
    getTokenForAppointment: (mobile_number, booking_code, service_id) => dispatch(getTokenForAppointment(mobile_number, booking_code, service_id)),
    getTokenForSeniorCitize: (mobile_number, service_id, patient_id, has_senior_citizen, token_for) => dispatch(getTokenForService(mobile_number, service_id, patient_id, has_senior_citizen, token_for)),
});

ServicesContainer = connect(mapStateToProps, mapStateToDispatch)(ServicesContainer);

export default ServicesContainer;