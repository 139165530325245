/**
 *  @description
 *  Services component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import ServiceComponent  from './service-component';
import HeaderComponent from '../layout/header-component';
import {
    Row,
    Col,
    Container
} from 'reactstrap';
import FooterComponent from '../layout/footer-component';

class ServicesComponent extends Component {

    render() {
        var response = this.props.services.data;
        var services = [], 
            serviceList = <Col><div className="text-center mt-5">No Services configured yet.</div></Col>;
        if(response) {
            services = response.data.services;
            serviceList = services.map((service) => (
                <Col xs="12" md="6" xl="4" className="px-3 py-2 py-md-3" key={service.id}>
                    <ServiceComponent 
                        service={service} 
                        onClicked={this.props.onServiceClicked} 
                        />
                </Col>
            ));
        };
        return (
            <>
                <HeaderComponent branch={this.props.branch}/>
                <Container fluid={true} className="container-minheight" >
                <Row className="justify-content-center mt-3">
                    {serviceList}
                </Row>
           </Container>
                <FooterComponent></FooterComponent>
            </>
        )
    }
}

export default ServicesComponent;