/**
 *  @description
 *  All constants for the App.
 *  @author
 *  Sanjay Kumar<sanjay&#64;myhealthcare.life>
 *  @version
 *  1.0.0 (01-Sep-2022)
 */

 export const APP_SUB_FOLDER = "";
//  export const APP_SUB_FOLDER = "/ilsa";
//  export const APP_SUB_FOLDER = "/ilsd";
//  export const APP_SUB_FOLDER = "/ilsh";
//  export const APP_SUB_FOLDER = "/ilss";


//  export const APP_SUB_FOLDER = "/ilsa-dev";
//  export const APP_SUB_FOLDER = "/ilsd-dev";
//  export const APP_SUB_FOLDER = "/ilsh-dev";
//  export const APP_SUB_FOLDER = "/ilss-dev";
 

// export const API_URI = 'https://sandboxapiportal.mhea.myhealthcare.co/api/qms/android/v1';
// export const PORTAL_URI = 'https://sandboxagentportal.mhea.myhealthcare.co/';


// export const API_URI = 'https://mhcdev.api.myhealthcare.co/api/qms/android/v1';
// export const PORTAL_URI = 'https://mhcdev.agentportal.myhealthcare.co';

export const API_URI = 'https://mhcapi.myhealthcare.life/api/qms/android/v1';
export const PORTAL_URI = 'https://mhc.myhealthcare.life';


export const hospitalName = 'ILS Hospitals';
export const WEBSOCKET_URI = 'ws://192.168.1.154:8000';
export const POLLING_DELAY = 60000;
export const TOKEN_DIALOG_TIMEOUT = 12000;