/**
 *  @description
 *  Mobile number component.
 *  @author
 *  Mahesh Hegde<mahesh@innocirc.com>
 *  @version
 *  1.0.0 (12-Feb-2019)
 */

import React, { Component } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';

import {TOKEN_DIALOG_TIMEOUT, APP_SUB_FOLDER} from '../../../config/app-constants'
import {connect} from 'react-redux';
import {
    getTokenForService,
    getTokenForAppointment
} from '../../actions/token-actions';


export default class MobileNumberNotExist extends Component {

    mainBox;

    constructor(props) {
        super(props);
        this.state = {
            mobile_number: '',
            mainBoxHeight: 0,
            errors: '',
        };
        this.getToken = this.getToken.bind(this);
    }

    componentDidMount() {
        this.setState({
            mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let height = this.mainBox ? this.mainBox.clientHeight : 0;
        if(prevState.mainBoxHeight !== height) {
            this.setState({
                mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
            });
        }
    }

    

    /** Mobile number form submit handler. responsible for validation. */
    getToken(e, data) {
        e.preventDefault();
        // let get_service_id = this.props.service.id == 1? 3 :this.props.service.id;
        let get_service_id = this.props.token.data.data.service.id;
        let has_senior_citizen = this.props.service.id == 1? 'true' :'false';
        this.props.getTokenForSeniorCitize(this.props.mobileNumber, get_service_id, null, has_senior_citizen, false)
                .then((data) => {
                    if (data.data) {
                        this.props.toggleNoUserExistsModal();
                        this.props.toggleTokenModal();
                        setTimeout(() => {
                            this.props.closeTokenModal();
                        }, TOKEN_DIALOG_TIMEOUT);
                        
                    }
                });
    }

    // getToken(e, data) {
    //     e.preventDefault();
    //     this.props.getTokenForAppointment(this.props.mobileNumber, this.props.service.id, false, 'true')
    //         .then((data) => {
    //             if (data.data) {
    //                 this.props.toggleNoUserExistsModal();
    //                 this.props.toggleTokenModal();
    //                 setTimeout(() => {
    //                     this.props.closeTokenModal();
    //                 }, TOKEN_DIALOG_TIMEOUT);
                    
    //             }
    //     });
    // }

    render() {
        return (
            <>
                <div className='modal-header'>
                        <h5>Mobile Number {this.props.mobileNumber}</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                    </div>
                    <Form className="py-4 main-box" autocomplete="off" onSubmit={(e) => this.getToken(e)} ref={ (mainBox) => this.mainBox = mainBox}>
                            <div className='row text-center not_exists'>
                                <div className='col-12'>
                                    <p>You do not seem to be registered with us. <br />Please proceed to the registration desk.</p>
                                    {/* <p><span className='red'>To save the time, register your self by scanning the QR code</span> or <span className='red'>by visiting </span><a target="_blank" href="#">xxxx</a></p> */}
                                </div>
                                {/* <div className='col-2'><div className='qrcode'><img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/qrcode.png"} alt="QR code" className="img-fluid" /></div></div> */}
                                <div className='col-12 text-center pt-4 mb-0 position-relative form-group'><button type="submit" className="px-5 btn btn-primary">Get Token</button>
                                </div>
                            </div>
                    </Form>
            </>
        )
    }
 }

 
 const mapStateToProps = state => ({
    pair: state.pair,
    services: state.services,
    //websocket: state.websocket,
    token: state.token
});

const mapStateToDispatch = dispatch => ({
    getTokenForAppointment: (mobile_number, service_id) => dispatch(getTokenForService(mobile_number, service_id, null, null)),
    // getTokenForAppointment: (mobile_number, service_id, patient_id, has_senior_citizen) => dispatch(getTokenForService(mobile_number, service_id, patient_id, has_senior_citizen)),
    getTokenForSeniorCitize: (mobile_number, service_id, patient_id, has_senior_citizen, token_for) => dispatch(getTokenForService(mobile_number, service_id, patient_id, has_senior_citizen, token_for)),
});

MobileNumberNotExist = connect(mapStateToProps, mapStateToDispatch)(MobileNumberNotExist);