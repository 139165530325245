/**
 *  @description
 *  Pairing component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import PairingCodeBoxComponent from './pairing-code-box-component';
import HeaderComponent from '../layout/header-component';
import { APP_SUB_FOLDER } from '../../../config/app-constants';

class PairingComponent extends Component {
    render() {
        var code = "LOADING";
        if(this.props.pair && this.props.pair.data) {
            code = this.props.pair.data.data.otp;
        }
        return (
            <div className="home-banner">
                <div className="container">
                    <div className="banner-header">
                      <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/logo.png"} alt="Logo" className="img-fluid" />
                      </div>
                        <PairingCodeBoxComponent code={code} />
                        
                        <div className="banner-footer">
                            <p>Powered By</p>
                            <figure>
                                <img  src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/logo-myhealthcare.svg"} alt="myhealthcare" />    
                            </figure>
                        </div>   
                        </div>
            </div>
        )
    }
}

export default PairingComponent;