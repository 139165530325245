/**
 *  @description
 *  App routes related to App.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react'
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { history } from '../redux/store/configure-store';
import PrivateRoute from './private-route';
import PairingContainer from '../redux/containers/pairing-container';
import ServicesContainer from '../redux/containers/services-container';
import { APP_SUB_FOLDER } from '../config/app-constants';

class AppRoutes extends Component {
    render() {
        let isPaired = false;
        if(this.props.pair && this.props.pair.data && this.props.pair.data.data.status) {
            isPaired = this.props.pair.data.data.status;
        }
        return (
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path={`${APP_SUB_FOLDER}/`} component={PairingContainer} />
                    <PrivateRoute exact path={`${APP_SUB_FOLDER}/services`} isPaired={isPaired} component={ServicesContainer} />
                    <Route component={PairingContainer} />
                </Switch>
            </ConnectedRouter>
        )
    }
}

const mapStateToProps = state => ({
    pair: state.pair,
});

AppRoutes = connect(mapStateToProps)(AppRoutes);

export default AppRoutes;