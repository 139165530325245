/**
 *  @description
 *  Mobile number component.
 *  @author
 *  Mahesh Hegde<mahesh@innocirc.com>
 *  @version
 *  1.0.0 (12-Feb-2019)
 */

import React, { Component } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import { hospitalName } from '../../../config/app-constants';

export default class MobileNumberFormComponent extends Component {
    mainBox;
    constructor(props) {
        super(props);
        this.state = {
            mobile_number: '',
            mainBoxHeight: 0,
            errors: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.getToken = this.getToken.bind(this);
    }

    componentDidMount() {
        this.setState({
            mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let height = this.mainBox ? this.mainBox.clientHeight : 0;
        if(prevState.mainBoxHeight !== height) {
            this.setState({
                mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
            });
        }
    }

    /** Update the state on input changes. */
    handleChange(e) {
        let target = e.target;
        const value = target.value;
        const name = target.name;
        if(name === 'mobile_number') {
            target.value = target.value.replace(/[^\d]/,'')
        }
        this.setState({
            [name]: value,
        });
    }

    /** Mobile number form submit handler. responsible for validation. */
    getToken(e) {
        e.preventDefault();
        if(this.state.mobile_number.length === 0) {
            this.setState({
                errors: 'Mobile number is required.',
            });
        }
        else if (this.state.mobile_number.length !== 10) {
            this.setState({
                errors: 'Mobile number should be 10 digit.',
            });
        }
        else {
            this.setState({
                errors: '',
            });
            this.props.getToken(e, this.state);
        }
    }

    render() {
        return (
            <>
                {/* <Row>
                    <Col xs={{size: 4, offset: 4}} className="text-center">
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo-white.png"} alt="Logo" className="img-fluid" />
                    </Col>
                </Row> */}
                <div className='modal-header'>
                        <h5>{this.props.service.title}</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                </div>
                
                <Form className="main-box" autocomplete="off" onSubmit={(e) => this.getToken(e)} ref={ (mainBox) => this.mainBox = mainBox}>
                    <FormGroup className="pt-3">
                        <div>
                            <Input type="tel" name="mobile_number" placeholder="Mobile number" maxLength="10" autocomplete="off" onChange={this.handleChange} />
                        </div>
                        <h6>Please use mobile number registered with {hospitalName}</h6>
                        <div className="text-left">
                            <small className="text-danger">{this.state.errors}</small>
                        </div>
                    </FormGroup>
                    <FormGroup className="text-center py-4">
                        <Button type="submit" color="primary" className="px-5">Continue</Button>
                    </FormGroup>
                </Form>
            </>
        )
    }
 }