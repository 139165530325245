/**
 *  @description
 *  Service component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

 import React, {
    Component
} from 'react';
import {
    Card,
    CardBody,
    // Tooltip
} from 'reactstrap';
import {
    PORTAL_URI
} from '../../../config/app-constants';
import { Interweave   } from 'interweave';

class ServiceComponent extends Component {

    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
        //   tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
        //   tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        if(this.props.service) {
            const service = this.props.service;
            let serviceTitle = service.title; 
            serviceTitle = serviceTitle.replace("OR", "<span>OR</span>");
            return (
                <Card className="service-card h-100 new_token_card" onClick={() => { this.props.onClicked(service) }}>
                    <CardBody>
                    <figure className="icon_wrap"><img src={ PORTAL_URI + "/uploads/" + service.image} alt="" className="card-icon" /></figure>
                  
                               <div className="card-text get_token">                            
                                
                                <Interweave className="mb-0" tagName="h5" content={serviceTitle} />
                                {/* <h5>OPD Consult <br /> APPT/ Walk-In</h5> */}
                                {/* <h6>Tap Here</h6> */}
                                
                            </div>
                    </CardBody>
                </Card>
            )
        } else {
            return (<div></div>)
        }
    }
}

export default ServiceComponent;